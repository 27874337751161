import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './styles.css';

import { Amplify } from 'aws-amplify';

Amplify.configure({
  Auth: {
    Cognito: {
    // REQUIRED - Amazon Cognito User Pool ID
    userPoolId: 'eu-central-1_PyPGBzqFK',
    // REQUIRED - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolClientId: 'vhu3u7fbqtbnbio11kfto2fo1',
    // OPTIONAL - Amazon Cognito Region
    region: 'eu-central-1',
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    // set authentication type
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  }
}
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);
