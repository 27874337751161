import axios from 'axios';
import config from '../config/deployment-config.json';

// Extract the API URL
const API_URL = config.ApiBaseUrl;

export const queryLocations = async (question, token) => {
  return axios.post(`${API_URL}/query`, question, {
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json"
    }
  });
};
