import React, { useEffect } from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate and useLocation
import {
  ThemeProvider,
  Theme
} from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css';

const LoginPage = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  const location = useLocation(); // Initialize useLocation

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const redirectPath = searchParams.get('redirect');

    if (redirectPath) {
      navigate(redirectPath);
    }
  }, [navigate, location]);


  const grayTheme: Theme = {
    name: 'Gray Theme',
    tokens: {
      colors: {
        background: {
          primary: { value: '#F5F5F5' },  // WhiteSmoke
          secondary: { value: '#F5F5F5' },  // Gainsboro
        },
        font: {
          primary: { value: '#212121' },  // Jet (dark gray)
          secondary: { value: '#757575' },  // Sonic Silver
          interactive: { value: '#BDBDBD' },  // Silver
        },
        brand: {
          primary: { value: '#616161' },  // Dim Gray 
          secondary: { value: '#9E9E9E' },  // Spanish Gray
          highlight: { value: '#EEEEEE' },  // White Smoke
        },
        border: {
          primary: { value: '#BDBDBD' },  // Silver
          secondary: { value: '#E0E0E0' },  // Gainsboro
        },
      },
      components: {
        tabs: {
          item: {
            color: { value: '#212121' },
            colorHover: { value: '#212121' },
            colorActive: { value: '#212121' },
          },
        },
        button: {
          primary: {
            backgroundColor: { value: '#212121' },
            color: { value: '#212121' }
          },
        },
      },
    },
  };
  

  return (
      <ThemeProvider theme={grayTheme} >
    <Authenticator initialState="signIn" hideSignUp>
      {({ signOut, user }) => (
        <main>
          <h1>Hello {user.username}</h1>
          <button onClick={signOut}>Sign out</button>
        </main>
      )}

    </Authenticator>
    </ThemeProvider>
      
  );
};

export default LoginPage;
