import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';
import profileIcon from './profile.png';

const Header = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.metaKey && event.key === 'k') {
        event.preventDefault(); // Prevents the default save action in some browsers
        navigate('/', { state: { focusSearch: true, clearSearch: true } });
      } else if (event.metaKey && event.key === 'u') {
        event.preventDefault();
        navigate('/locations');
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [navigate]);


  return (
    <header className="header">
      <h1>Wim</h1>
      <nav className="header-nav">
        <ul>
          <li><Link to="/" title="Cmd+K">Find</Link></li>
          <li><Link to="/locations" title="Cmd+U">Update</Link></li>
          <li>
            <Link to="/login">
              <img src={profileIcon} alt="Profile" className="profile-icon" />
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;