import React, { useState, useEffect, useRef } from 'react';
import './Search.css';
import { fetchAuthSession } from 'aws-amplify/auth';
import { ThreeDots } from 'react-loader-spinner';
import { queryLocations } from '../api/query'; // Assuming the query.js is placed in the api directory
import { useNavigate , useLocation} from 'react-router-dom'; // Use useNavigate instead of useHistory

const Search = ({ autoFocus }) => {
  const [query, setQuery] = useState('');
  const [result, setResult] = useState('');
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [showJson, setShowJson] = useState(false); // State to toggle JSON view
  const searchInputRef = useRef(null); // Create a ref for the textarea
  
  
  const navigate = useNavigate(); // Initialize useNavigate
  const location = useLocation(); // Get the location object


  useEffect(() => {
    const getAuthToken = async () => {
      try {
        const { tokens } = await fetchAuthSession();
        setToken(tokens.idToken.toString());
      } catch (error) {
        console.error('Error fetching auth token:', error);
        navigate('/login');
      }
    };

    getAuthToken();
  }, [navigate]);

  // useEffect(() => {
  //   if (autoFocus && searchInputRef.current) {
  //     searchInputRef.current.focus(); // Focus the textarea if autoFocus is true
  //   }
  // }, [autoFocus]);


  useEffect(() => {
    if (autoFocus && searchInputRef.current) {
      searchInputRef.current.focus(); // Focus the textarea if autoFocus is true
    }
    if (location.state?.clearSearch) {
      setQuery(''); // Clear the query if clearSearch is true
    }
  }, [autoFocus, location.state?.clearSearch]);
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!token) {
      setResult('No authentication token available');
      return;
    }

    setLoading(true);
    setResult('');

    try {
      const response = await queryLocations(query, token);
      setResult(response.data);
    } catch (error) {
      console.error('Error fetching the data', error);
      setResult('Error fetching the data');
    } finally {
      setLoading(false);
    }
  };


  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const handleMouseOver = () => {
    setShowJson(true);
  };

  const handleMouseOut = () => {
    setShowJson(false);
  };
  return (
    <div className="home-container">
      <form onSubmit={handleSubmit}>
        <textarea
          ref={searchInputRef} 
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Enter your query"
          onKeyPress={handleKeyPress}
          rows="3"
        />
        <button type="submit" disabled={loading}>
          {loading ? 'Looking...' : 'Find'}
        </button>
      </form>
      {loading && (
        <div className="loader-container">
          <ThreeDots color="#00BFFF" height={80} width={80} />
        </div>
      )}

        {result && !loading && (
        <div
          className="result-container"
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          {showJson ? (
            <pre>{JSON.stringify(result, null, 2)}</pre>
          ) : (
            <BeautifulResult result={result} />
          )}
        </div>
      )}

      
    </div>
  );
};


const BeautifulResult = ({ result }) => {
  // Customize this component to display the result in a visually appealing way
  return (
    <div className="beautiful-result">
      <p>{result.result}</p>
    </div>
  );
};

export default Search;
