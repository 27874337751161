import React, { useEffect, useState } from 'react';
import { getLocations, updateLocation } from '../api/locations';
import LocationList from '../components/LocationList';
import { fetchAuthSession } from 'aws-amplify/auth';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate and useLocation

import './Locations.css';

const Locations = () => {
  const [locations, setLocations] = useState([]);
  const [token, setToken] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate
  const location = useLocation(); // Initialize useLocation

  useEffect(() => {
    const getAuthToken = async () => {
      try {
        const { tokens } = await fetchAuthSession();
        setToken(tokens.idToken.toString());
        console.log('Token:', tokens.idToken.toString());
      } catch (error) {
        console.error('Error fetching auth token:', error);
        navigate(`/login?redirect=${location.pathname}`); // Redirect to login with the intended path
      }
    };

    getAuthToken();
  }, [navigate, location.pathname]);

  useEffect(() => {
    const fetchLocations = async () => {
      if (!token) return;
      
      try {
        const response = await getLocations(token);
        console.log('Locations:', response.data);
        // If locations length > 0, set locations, otherwise, set locations to emply array
        if (response.data.length)
          setLocations(response.data);
        else
          setLocations([{ id: "1", text: '' }]);

          
      } catch (error) {
        console.error('Error fetching locations', error);
      }
    };

    fetchLocations();
  }, [token]);

  const handleUpdateLocation = async (id, text) => {
    try {
      console.log("Token for location update:", token);
      console.log("Location ID:", id);
      console.log("Location Text:", text);
      await updateLocation(id, text, token);
      setLocations(locations.map((location) =>
        location.id === id ? { ...location, text } : location
      ));
    } catch (error) {
      console.error('Error updating location', error);
    }
  };

  return (
    <div className="location-container">
      <LocationList
        locations={locations}
        isLoading={!locations.length}
        onUpdateLocation={handleUpdateLocation}
      />
    </div>
  );
};

export default Locations;
