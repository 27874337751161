import axios from 'axios';
import config from '../config/deployment-config.json';

// Extract the API URL
const API_URL = config.ApiBaseUrl;

export const getLocations = async (token) => {
  return axios.get(`${API_URL}/locations`, {
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json"
    }
  });
};

export const createLocation = async (locationText) => {
  return axios.post(`${API_URL}/locations`, locationText);
};

export const getLocationById = async (id) => {
  return axios.get(`${API_URL}/locations/${id}`);
};

export const updateLocation = async (id, locationText, token) => {
  return axios.post(`${API_URL}/locations`, {
    id: id,
    text: locationText
  }, {
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json"
    }
  });
};

export const queryLocations = async (question) => {
  return axios.post(`${API_URL}/query`, { question });
};
