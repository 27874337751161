import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { fetchAuthSession } from 'aws-amplify/auth';
import Header from './components/Header';
import Footer from './components/Footer';
import Search from './pages/Search';
import Profile from './pages/Profile';
import Locations from './pages/Locations';
import Login from './pages/Login';
import './App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const reactLocation = useLocation(); 

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        await fetchAuthSession();
        setIsAuthenticated(true);
      } catch (error) {
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkAuthStatus();
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="app-wrapper">
      <Header />
      <div className="main-content">
        <Routes>
          <Route path="/" element={isAuthenticated ? <Search autoFocus={reactLocation.state?.focusSearch} /> : <Navigate to="/login" />} />
          <Route path="/login" element={<Login onLogin={handleLogin} />} />
          <Route path="/profile" element={isAuthenticated ? <Profile /> : <Navigate to="/login" />} />
          <Route path="/locations" element={isAuthenticated ? <Locations /> : <Navigate to="/login" />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
