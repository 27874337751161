import React, { useState, useEffect } from 'react';
import './LocationList.css';
import ReactMarkdown from 'react-markdown';

const LocationList = ({ locations, isLoading, onUpdateLocation }) => {
  const [editableLocation, setEditableLocation] = useState(null);
  const [updatedText, setUpdatedText] = useState('');

  useEffect(() => {
    if (locations.length > 0) {
      setEditableLocation(locations[0].id);
      setUpdatedText(locations[0].text);
    }
  }, [locations]);

  const handleEdit = (location) => {
    setEditableLocation(location.id);
    setUpdatedText(location.text);
  };

  const handleUpdate = (id) => {
    onUpdateLocation(id, updatedText);
    setEditableLocation(null);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      
        {locations.map((location) => (
          <div  className="location-list-container">
            
              <textarea 
                className="location-textarea"
                value={updatedText}
                onChange={(e) => setUpdatedText(e.target.value)}
              />
        
             <br />
            {editableLocation === location.id ? (
              <button onClick={() => handleUpdate(location.id)}>Update</button>
            ) : (
              <button onClick={() => handleEdit(location)}>Edit</button>
            )}
          </div>
        ))}
      
    </div>
  );
};

export default LocationList;
